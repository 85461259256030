export default {
    "catalog_help_page_text_1": "Im Menü \"Neuen Eintrag anlegen\" können Sie den Titel des Eintrags festlegen. Falls gewünscht, können Sie zudem einen Datensatz mit dem Eintrag verknüpfen.",
    "entity_other_views": "Weitere Ansichten",
    "entity_create_entry": "Katalogeintrag anlegen",
    "entity_catalogs": "Kataloge",
    "entity_places": "Orte",
    "entity_linked_objects": "Verknüpfte Objekte",
    "entity_no_further_info": "Keine weiteren Angaben eingetragen",
    "entity_images": "Abbildungen",
    "entity_analysis": "Analyse",
    "entity_image": "Abbildung",
    "entity_3dmodel": "3D-Modell",
    "ui_category": "Kategorie",
    "ui_serial_number": "Alte Seriennummer",
    "facet_subkategorie_bilder": "Bestandstyp",
    "facet_subkategoriebestand_level15": "Unterkategorie (15)",
    "facet_subkategoriebestand_level14": "Unterkategorie (14)",
    "facet_subkategoriebestand_level13": "Unterkategorie (13)",
    "facet_subkategoriebestand_level12": "Unterkategorie (12)",
    "facet_subkategoriebestand_level11": "Unterkategorie (11)",
    "facet_subkategoriebestand_level10": "Unterkategorie (10)",
    "facet_subkategoriebestand_level9": "Unterkategorie (9)",
    "facet_subkategoriebestand_level8": "Unterkategorie (8)",
    "facet_subkategoriebestand_level7": "Unterkategorie (7)",
    "facet_subkategoriebestand_level6": "Unterkategorie (6)",
    "facet_subkategoriebestand_level5": "Unterkategorie (5)",
    "facet_subkategoriebestand_level4": "Unterkategorie (4)",
    "facet_subkategoriebestand_level3": "Unterkategorie (3)",
    "facet_subkategoriebestand_level2": "Unterkategorie (2)",
    "facet_subkategoriebestand_level1": "Unterkategorie (1)",
    "facet_archaeometrischemessungen": "Archäometrische Messungen",
    "facet_einbindung": "Einbindung",
    "facet_produktform": "Produktform",
    "facet_produktfunktion": "Produktfunktion",
    "facet_produktmaterialspezifizierung": "Produktgattung - Spezifizierung",
    "facet_produktmaterial": "Produktgattung",
    "facet_geo_map": "Alle Ortsangaben",
    "facet_fundort_map": "Fundort",
    "facet_aufbewahrungsort_map": "Aufbewahrungsort",
    "facet_geo_plural": "Alle Ortsangaben",
    "facet_fundort_plural": "Fundorte",
    "facet_aufbewahrungsort": "Aufbewahrungsort",
    "subtitle": "Untertitel",
    "title": "Titel",
    "entityId": "Arachne-ID",
    "facet_gattungallgemein": "Gattung",
    "facet_sprache": "Sprache",
    "facet_herkunftsland": "Herkunftsländer",
    "facet_sammlungskategorie": "Sammlungskategorie",
    "facet_personentiere": "Tiere",
    "facet_personenmythwesen": "myth. Wesen",
    "facet_personenmenschen": "Menschen",
    "facet_personenheroen": "Heroen",
    "facet_dekoration": "Dekoration",
    "facet_personengoetter": "Götter",
    "facet_personen": "Personen",
    "facet_bestandsname": "Bestandsname",
    "facet_roemgriech": "Gattungsbereich",
    "facet_haltung": "Haltung",
    "facet_bildschema": "Bildschema",
    "facet_bekleidung": "Bekleidung",
    "facet_klassifizierung": "Klassifizierung",
    "data_export_to_huge": "Diese Datenmenge ist zu groß und kann nicht exportiert werden.",
    "type_sammler": "Sammler",
    "facet_befundmainabstractmorphology": "Morphologie",
    "facet_fundort": "Fundort",
    "register_register": "Registrieren",
    "register_success": "Registrierung erfolgreich. Sie sollten in Kürze eine eMail von uns erhalten.",
    "register_please_fill_out": "Bitte füllen Sie das folgende Formular aus um einen Account für Arachne zu beantragen.",
    "ui_reset": "Zurücksetzen",
    "ui_phone": "Telefon",
    "ui_street": "Straße",
    "ui_address": "Adresse",
    "ui_country": "Land",
    "ui_repeat": "eMail wiederholen",
    "ui_surname": "Nachname",
    "ui_first_name": "Vorname",
    "facet_areal": "Areal",
    "catalog_help_page_text_0": "Beginnen Sie mit der Erstellung eines neuen Katalogs, indem Sie einen Katalogeintrag anlegen.",
    "catalog_delete_type_catalog_name": "Bitte geben Sie den vollständigen Namen des zu löschenden Kataloges ein, um die Kataloglöschung zu bestätigen.",
    "catalog_show_help": "Hilfe anzeigen",
    "catalog_help": "Hilfe",
    "ui_previous_queries": "Frühere Suchanfragen",
    "ui_password_changed": "Passwort erfolgreich geändert!",
    "ui_repeat_password": "Passwort wiederholen",
    "facet_grundform": "Grundform",
    "ui_pages": "Seiten",
    "facet_shape": "Form",
    "entity_active_filters": "Aktive Filter",
    "entity_next_result": "Nächstes Ergebnis",
    "entity_previous_result": "Voriges Ergebnis",
    "entity_shown_result": "Angezeigter Datensatz",
    "ui_to_search_result": "Zum Suchergebnis",
    "ui_sort_order": "Sortierung",
    "startsite_projects": "Projekte",
    "startsite_categories": "Kategorien",
    "ui_entry": "Eintrag",
    "ui_current_query": "Aktuelle Suche",
    "startsite_show_all_projects": "Alle Projekte anzeigen",
    "startsite_show_all_categories": "Alle Kategorien anzeigen",
    "ui_map": "Karte",
    "footer_imprint": "Impressum",
    "catalog_help_page_text_3": "Katalogeinträge können unterhalb eines bereits bestehenden Eintrags angelegt werden, um eine neue Ebene in der Hierarchie zu erzeugen.",
    "catalog_help_page_text_2": "Benutzen Sie den Editor, um den Text des Katalogfelds einzugeben und zu formatieren. Dabei stehen Ihnen die Möglichkeiten der Auszeichnungssprache Markdown zur Verfügung.",
    "navbar_manage_catalogs": "Kataloge verwalten",
    "navbar_contact": "Kontakt",
    "navbar_about_arachne": "Über Arachne",
    "navbar_license_order": "Lizenz/Bestellung",
    "facet_mischwesen": "Mischwesen",
    "facet_ergaenzungen": "Ergänzungen",
    "facet_gattungspeziell": "Gattung Zusatz",
    "facet_gottgoettin": "Gottheit",
    "facet_ortsangabe": "Typ der Ortsangabe",
    "ui_hideall": "Alle ausblenden",
    "ui_projectsearchplaceholder": "Projekt durchsuchen...",
    "facet_kategorie_modell3d": "3D-Modelle",
    "facet_aufbewahrungsort_plural": "Aufbewahrungsorte",
    "facet_subkategorie_objekt": "Unterkategorie",
    "facet_image": "Enthält Bilder",
    "facet_literatur": "Literatur",
    "facet_hasImage": "Daten mit Bildern",
    "facet_geo": "Georeferenzierte Orte",
    "facet_einschlussform": "Einschlussform",
    "facet_einschlusstyp": "Einschlusstyp",
    "facet_grabungsort": "Grabungsort",
    "facet_surfacetreatmentbezeichner": "Bezeichner Oberflächenbehandlung",
    "facet_nitonanalysisnumber": "Nitonanalysenummer",
    "facet_inventorynumber": "Inventarnummer",
    "facet_sherdtype": "Scherbentyp",
    "facet_stratum": "Stratum",
    "facet_grabungsinternetypnummer": "Grabungsinterne Typnummer",
    "facet_morphology_5thLevel": "Morphologie 5. Level",
    "facet_morphology_4thLevel": "Morphologie 4. Level",
    "facet_morphology_3rdLevel": "Morphologie 3. Level",
    "facet_morphology_2ndLevel": "Morphologie 2. Level",
    "facet_morphology_functionalgroup": "Morphologie Funktionale Gruppe",
    "facet_fabricname": "Name Fabrikat",
    "facet_befund": "Befund",
    "facet_fabricorigin": "Herkunft",
    "facet_schmuckspezifizierung": "Schmuckspezifizierung",
    "facet_materialbeschreibung": "Materialbeschreibung",
    "facet_applizierteelemente": "Applizierte Elemente",
    "facet_dekorationsart": "Dekorationsart",
    "facet_topographietypus": "Topographietypus",
    "facet_topographieart": "Topographieart",
    "facet_jahr": "Jahr",
    "facet_buchtitel": "Buchtitel",
    "facet_autor": "Autor",
    "facet_regioromitalien": "Regio Rom Italien",
    "facet_antikeroemprovinz": "antike röm. Provinz",
    "facet_bauordnung": "Bauordnung",
    "facet_kontext": "Kontext",
    "facet_gebaeudetypspeziell": "Gebäudetyp speziell",
    "facet_thematikmenschen": "Thematik Menschen",
    "facet_archaeologzeugnisse": "Archaeolog. Zeugnisse",
    "facet_thematik": "Thematik",
    "facet_aufstellungskontext": "Aufstellungskontext",
    "facet_aufbau": "Aufbau",
    "facet_artdergruppe": "Art der Gruppe",
    "facet_antikegriechlandschaft": "antike griech. Landschaft",
    "facet_technik": "Technik",
    "facet_material": "Material",
    "facet_kulturkreis": "Kulturkreis",
    "facet_funktionaleverwendung": "funktionale Verwendung",
    "facet_funktion": "Funktion",
    "facet_erhaltung": "Erhaltung",
    "facet_bearbeitungen": "Bearbeitungen",
    "facet_objektgattung": "Objektgattung",
    "facet_gebaeudetyp": "Gebäudetyp",
    "facet_datierungepoche": "Datierung, Epoche",
    "facet_ort": "Ort",
    "facet_kategorie": "Kategorie",
    "type_sammlungen": "Sammlungen",
    "ui_current_search": "Aktuelle Suche",
    "ui_gridmap_heading": "Georeferenzierte Objekte in Arachne",
    "ui_map_menu_overlays_heading": "Overlays",
    "ui_map_menu_baselayer_heading": "Karten",
    "ui_restricted_entities": "Nur Objekte mit",
    "ui_object": "Objekt",
    "ui_object_plural": "Objekte",
    "ui_at": "an",
    "entity_linked_object": "Verknüpftes Objekt",
    "ui_place_plural_dative": "Orten",
    "ui_place": "Ort",
    "ui_showobjects": "Objekte anzeigen",
    "ui_total_small": "insgesamt",
    "ui_no_places_found": "Keine Orte gefunden",
    "ui_showallobjects": "Alle Objekte anzeigen",
    "catalogIds": "Katalog",
    "ui_copy_to_clipboard_message": "Kopieren in die Zwischenablage mit Strg+C/⌘+C",
    "ui_map_link_heading": "Link zur aktuellen Kartenansicht",
    "ui_of": "von",
    "ui_objects_shown": "Objekte im Bildausschnitt",
    "search_catalog_create": "Katalog aus Suchergebnis erstellen",
    "search_catalog_too-big": "Das Suchergebnis ist zu groß, um einen Katalog anzulegen. Nutzen Sie die Filter, um das Ergebnis einzugrenzen. Die Maximale Größe ist",
    "ui.register.bot": "Entschuldigung, eine Registrierung für Roboter ist ausgeschlossen!",
    "ui.register.fieldMissing.lastname": "Bitte geben Sie Ihren Nachnamen an, um mit der Registrierung fortzufahren.",
    "ui.register.fieldMissing.email": "Bitte geben Sie eine Email-Adresse ein, um mit der Registrierung fortzufahren.",
    "ui.register.fieldMissing.firstname": "Bitte geben Sie Ihren Vornamen ein, um mit der Registrierung fortzufahren.",
    "ui.register.fieldMissing.street": "Bitte geben Sie eine Straße an, um mit der Registrierung fortzufahren.",
    "i.register.fieldMissing.country": "Bitte wählen Sie ein Land aus, um mit der Registrierung fortzufahren.",
    "ui.register.usernameTaken": "Der von Ihnen gewählte Benutzername ist bereits vergeben. Bitte wählen Sie einen anderen.",
    "publish_catalog": "Katalog veröffentlichen",
    "catalog_title": "Titel des Katalogs",
    "catalog_author": "Autor",
    "catalog_description": "Katalogbeschreibung",
    "catalog_text": "Katalogtext",
    "catalog_visibility": "Sichtbarkeit",
    "catalog_visibility_private": "Privat",
    "catalog_visibility_public": "Öffentlich",
    "ui.update.bot": "Sorry, Roboter dürfen keine Nutzerdaten ändern! Bitte bestätigen Sie, dass sie keine Maschine sind.",
    "ui.update.emailNotSame": "Die angegebenen Email-Adressen stimmen nicht überein.",
    "ui.update.success": "Die Aktualisierung Ihrer Nutzerdaten war erfolgreich.",
    "ui.update.manage": "Nutzerdaten verwalten / ändern",
    "ui.update.perform": "Aktualisieren",
    "everything": "Alles",
    "maintenance_arachne3": "Aktuell finden Wartungsarbeiten statt. Sie können weiterhin die neue Arachne 4 beta zur Recherche nutzen. Die Dateneingabe in Arachne 3 wird bald wieder verfügbar sein.",
    "entity_403": "Sie besitzen nicht die notwendigen Rechte, um diesen Datensatz anzuzeigen.",
    "ui.register.emailTaken": "Die von Ihnen angegebene Email-Adresse ist bereits vergeben. Haben Sie bereits einen Account? Wenn nicht, wählen Sie bitte eine andere Email-Adresse zur Registrierung.",
    "ui.register.fieldMissing.password": "Bitte geben Sie ein Passwort an, um mit der Registrierung fortzufahren.",
    "ui.register.fieldMissing.username": "Bitte geben Sie einen Benutzernamen an, um mit der Registrierung fortzufahren.",
    "ui.register.fieldMissing.zip": "Bitte geben Sie eine Postleitzahl an, um mit der Registrierung fortzufahren.",
    "ui.register.fieldMissing.place": "Bitte geben Sie einen Ort an, um mit der Registrierung fortzufahren.",
    "ui.register.fieldMissing.country": "Bitte wählen Sie ein Land aus, um mit der Registrierung fortzufahren.",
    "ui.register.emailsDontMatch": "Die von Ihnen eingegebenen Email-Adressen stimmen nicht überein.",
    "ui.register.passwordsDontMatch": "Die von Ihnen angegebenen Passwörter stimmen nicht überein.",
    "ui_zip": "Postleitzahl",
    "entity_catalog_info": "Kataloge mit diesem Eintrag",
    "catalog_help_page_text_4": "Verschieben Sie Einträge per Drag & Drop nach oben oder unten, um ihre Reihenfolge zu ändern.",
    "entity_404": "Die Anfrage konnte nicht ausgeführt werden.",
    "type_singular_bauwerk": "Bauwerk",
    "type_buchseite": "Buchseiten",
    "type_subtitle_bauwerk": "Gebäude oder Monumente, die auch übergeordnete Kontexte zu einem Einzelobjekt oder einem mehrteiligen Denkmal sein können. Ihrerseits können Bauwerke Teile einer topographischen Einheit sein, Reproduktionen oder Rezeptionen besitzen.",
    "type_bauwerk": "Bauwerke",
    "type_buch": "Bücher",
    "type_bauwerksteil": "Bauwerksteile",
    "type_inschrift": "Inschriften",
    "type_singular_bauwerksteil": "Bauwerksteil",
    "type_gruppen": "Mehrteilige Denkmäler",
    "type_subtitle_bauwerksteil": "Erfassung von Untergliederungen eines Gebäudes: Geschosse, Sektionen, Räume.",
    "type_realien": "Einzelmotive",
    "type_objekt": "Einzelobjekte",
    "type_singular_objekt": "Einzelobjekt",
    "type_subtitle_objekt": "Objekte der realen Welt, die keine mehrteiligen Denkmäler, keine Gebäude und keine topographische Einheit sind. Ihrerseits können Objekte aber zu mehrteiligen Denkmälern, Gebäuden oder topographischen Einheiten gehören, Reproduktionen oder Rezeptionen besitzen.",
    "type_subtitle_gruppen": "Alle Arten von Konfigurationen, die keine Gebäude sind: also antike Statuengruppen, nachantike Pasticci, Giebelkompositionen, modern getrennte Skulpturen, aber auch Fundgruppen oder Hortfunde; Sarkophage (da zwei Einzelobjekte: Kasten + Deckel); Bestattung/Grabinventar. Ferner Sammelaufnahmen mit vielen Kleinfunden, die nicht einzeln als Einzelobjekte angelegt wurden.",
    "type_relief": "Szenen",
    "type_singular_relief": "Szene",
    "type_subtitle_realien": "Figuren oder Gegenstände, die sich meist im Kontext von Szenen befinden.",
    "type_reproduktion": "Reproduktionen",
    "type_subtitle_reproduktion": "Dreidimensionale Objekte der realen Welt, die überwiegend antike Skulpturen oder Gebäude wiedergeben, andererseits auch Stichwerke. Ihrerseits können Reproduktionen zu mehrteiligen Denkmälern, Gebäuden, topographischen Einheiten oder Sammlungen gehören bzw. Ausdruck von Rezeptionen sein.",
    "type_marbilder": "Bilder",
    "type_singular_marbilder": "Bild",
    "type_rezeption": "Rezeptionen",
    "type_subtitle_marbilder": "Hier haben Sie die Möglichkeit, gezielt nach Bildern und bildspezifischen Informationen (z.B. Fotografen, etc.) zu suchen. Diese Suche erfasst alle Bildbestände in Arachne.",
    "type_typus": "Typen",
    "type_subtitle_rezeption": "Spezifische Wahrnehmungen antiker Objekte in bestimmten neuzeitlichen Rezeptionsquellen.",
    "type_topographie": "Topographien",
    "type_singular_typus": "Typ",
    "type_subtitle_topographie": "Übergeordnete Kontexte, die untergeordnete topographische Einheiten, Gebäude oder Objekte der realen Welt inkorporieren. Von Bauwerken abgegrenzt werden topographische Einheiten, wenn mehr als ein Bauwerk vorhanden ist - konstitutiv sind Bauwerke für topographische Einheiten jedoch nicht.",
    "type_subtitle_typus": "Kontextualisierung von Skulpturen (=Einzelobjekten) nach künstlerischen Bildnistypen und Bildnisschemata (griechische Idealplastik, griechisches und römisches Portrait).",
    "type_sammlung": "Sammlungen",
    "type_singular_topographie": "Topographie",
    "type_singular_rezeption": "Rezeption",
    "type_subtitle_sammlungen": "Privat- und Museumssammlungen, die in Gebäuden residieren und Objekte bzw. mehrteilige Denkmäler oder deren Reproduktionen und Rezeptionen enthalten können.",
    "type_singular_reproduktion": "Reproduktion",
    "type_singular_sammlungen": "Sammlung",
    "type_singular_buch": "Buch",
    "type_singular_realien": "Einzelmotiv",
    "type_singular_inschrift": "Inschrift",
    "type_singular_gruppen": "Mehrteiliges Denkmal",
    "type_singular_buchseite": "Buchseite",
    "type_ort": "Orte",
    "type_person": "Personen",
    "type_singular_ort": "Ort",
    "type_singular_person": "Person",
    "ui.register.fieldsMissing": "Bitte füllen Sie das Formular aus, um fortzufahren.",
    "ui.update.emailTaken": "Die von Ihnen gewählte Email-Adresse ist bereits vergeben, bitte wählen Sie eine andere.",
    "catalog_new_catalog": "Neuen Katalog anlegen",
    "ui_cancel": "Abbrechen",
    "ui_delete": "Löschen",
    "ui_sure_delete": "Wirklich löschen?",
    "ui_yes": "Ja",
    "ui_no": "Nein",
    "ui_dismiss": "Abbrechen",
    "catalog_create_entry": "Neuen Eintrag anlegen",
    "catalog_edit_entry": "Eintrag bearbeiten",
    "catalog_entry_title": "Titel des Eintrags",
    "catalog_please_login": "Für Kataloge bitte anmelden",
    "catalog_control": "Katalogverwaltung",
    "catalog_edit": "Katalog bearbeiten",
    "catalog_your_catalog": "Ihre Kataloge",
    "catalog_no_catalog": "Keine Kataloge vorhanden",
    "catalog_add": "Neuen Katalog anlegen",
    "catalog_expand_all": "Alle aufklappen",
    "catalog_collapse_all": "Alle zuklappen",
    "catalog_choose_catalog": "Bitte Katalog wählen",
    "ui_forgot_password": "Passwort vergessen",
    "ui.passwordreset.reset": "Passwort zurücksetzen",
    "ui.passwordreset.username": "Benutzername",
    "ui.passwordreset.email": "Email Adresse",
    "ui.passwordreset.first-name": "Vorname",
    "ui.passwordreset.surname": "Nachname",
    "ui.passwordreset.zip": "Postleitzahl",
    "ui.passwordreset.iamhuman": "Ich bin keine Maschine!",
    "ui.passwordreset.send": "Senden",
    "ui.passwordreset.bot": "Bitte bestätigen Sie, dass Sie kein Roboter sind.",
    "ui.passwordreset.fieldMissing.username": "Bitte geben Sie einen Benutzernamen an.",
    "ui.passwordreset.fieldMissing.email": "Bitte geben Sie Ihre Email-Adresse ein.",
    "ui.passwordreset.fieldMissing.firstname": "Bitte geben Sie Ihren Vornamen ein.",
    "ui.passwordreset.fieldMissing.all": "Bitte geben Sie Ihre Daten ein.",
    "ui.passwordreset.success": "Ihr Passwort wurde erfolgreich zurückgesetzt. Sie sollten in Kürze eine E-Mail erhalten.",
    "ui.passwordreset.fieldMissing.zip": "Bitte geben Sie Ihre Postleitzahl ein.",
    "entity.image.back_to": "Zurück zu",
    "entity.image.download": "Download",
    "entity.image.metadata": "Bildmetadaten",
    "entity.image.fullscreen": "Vollbild",
    "entity.image.all_images": "Alle Abbildungen",
    "ui.passwordreset.unkownuser": "Die von Ihnen eingegebenen Daten sind nicht korrekt oder es konnte kein Benutzer mit diesen Daten gefunden werden. Bitte versuchen Sie es erneut oder wenden Sie sich an idai.objects@dainst.de.",
    "ui.passwordactivation.success": "Ihr Passwort wurde erfolgreich gesetzt.",
    "ui.passwordactivation.newpassword": "Neues Passwort",
    "category_all": "Alle Kategorien in Arachne",
    "category_browse_specific": "mit Filtern durchsuchen (Filter-Index)",
    "category_all_data_sets": "Alle Datensätze in Arachne",
    "ui_goto": "Gehe zu",
    "entity_resolution_login": "Sie müssen angemeldet sein um dieses Bild in hoher Auflösung betrachten zu können.",
    "ui_show": "anzeigen",
    "navbar_edit_profile": "Profil Editieren",
    "projects_all_projects": "Alle Projekte in Arachne",
    "search_no_results": "Keine Ergebnisse gefunden zu",
    "search_no_results_rights": "Ggf. fehlen Ihnen die nötigen Zugriffsrechte. Bitte wenden Sie sich an die auf der Projekteinstiegsseite angegebene Kontaktadresse.",
    "search_current_filters": "mit den aktuellen Filtern",
    "ui_no_title": "hat keinen Titel",
    "3Dinfo_flight_control": "Flugsteuerung:",
    "3Dinfo_modeller": "Modellierer",
    "ui_license": "Lizenz",
    "3Dinfo_file_format": "Dateiformat",
    "ui_mouse_pointer": "Mauszeiger",
    "3Dinfo_look": "umschauen",
    "3Dinfo_left_mouse_button": "Linke Maustaste",
    "3Dinfo_right_mouse_button": "Rechte Maustaste",
    "ui_up": "Hoch",
    "ui_down": "Runter",
    "3Dinfo_move_forward": "Nach vorne bewegen",
    "3Dinfo_move_backward": "Nach hinten bewegen",
    "ui_left": "Links",
    "ui_right": "Rechts",
    "3Dinfo_move_left": "Nach Links bewegen",
    "3Dinfo_move_right": "Nach rechts bewegen",
    "3Dinfo_middle_mouse_button": "Mittlere Maustaste / Scrollrad",
    "3Dinfo_trackball_control": "Trackball Steuerung:",
    "3Dinfo_move_target": "Kamera Ziel bewegen",
    "3Dinfo_move_up": "Nach oben bewegen",
    "3Dinfo_move_down": "Nach unten bewegen",
    "3Dinfo_freeze": "Kamera einfrieren/lösen",
    "ui_create_link": "Link Anlegen",
    "ui_target": "Ziel",
    "ui_linkdescription": "Linkbeschreibung",
    "type_subtitle_relief": "Thematisch oder formal in sich geschlossene Sektion einer Figurenabfolge, die sich im Kontext eines Trägermediums befindet, z.B. eine Kampfgruppe innerhalb eines Frieszyklus.",
    "type_subtitle_inschrift": "Eine auf einem Träger, beispielsweise aus Stein, Holz oder auch Metall, angebrachte Schrift.",
    "type_subtitle_buch": "Digitalisierung, Charakterisierung und Kontextualisierung altertumswissenschaftlicher Drucke des 16. bis 19. Jahrhunderts.",
    "type_subtitle_buchseite": "Darstellung einzelner Buchseiten in hoher Auflösung.",
    "navbar_change_password": "Passwort ändern",
    "catalog_delete_entry": "Eintrag löschen",
    "catalog_delete": "Katalog löschen",
    "ui_show_more": "Mehr anzeigen",
    "catalog_show_entity": "Verknüpften Datensatz anzeigen",
    "catalog_show_text": "Katalogtext einblenden",
    "catalog_create_entry_child": "Neuen Eintrag unterhalb dieses Eintrags anlegen",
    "ui_more_entries": "weitere Einträge",
    "ui_one_more_entry": "ein weiterer Eintrag",
    "catalog_entry_entity": "Verknüpfter Datensatz",
    "ui_optional": "Optional",
    "ui_not_logged_in": "Sie sind nicht berechtigt diese Seite einzusehen. Bitte melden Sie sich an um fortzufahren.",
    "facet_projekttitel": "Projekt",
    "ui_search_too_big": "Die Suchmenge überschreitet 10000 Einträge. Bitte schränken Sie die Suche weiter ein.",
    "entity.image.rotate": "Drehen",
    "catalog_no_description": "Kein Katalogtext vorhanden",
    "ui_objects_total": "Objekte insgesamt",
    "catalog_public_by": "Öffentlicher Katalog von",
    "catalog_view_full_entry": "Vollansicht des Katalogeintrags",
    "ui_close": "Schließen",
    "catalog_browse": "Katalog öffnen",
    "catalog_generate_texts": "Texte der Katalogeinträge automatisch erstellen",
    "catalog_map": "Katalogeinträge auf Karte anzeigen",
    "catalog_list": "Kataloggliederung anzeigen",
    "type_literatur": "Literatur",
    "facet_land": "Land",
    "ui_countryname_eg": "Ägypten",
    "ui_countryname_al": "Albanien",
    "ui_countryname_dz": "Algerien",
    "ui_countryname_aq": "Antarktis",
    "ui_countryname_ag": "Antigua und Barbuda",
    "ui.no_title": "Ohne Titel",
    "facet_kommentar": "Kommentar",
    "catalog_help_page_text_5": "Verschieben Sie Einträge nach links oder rechts, um sie in eine andere Ebene in der Hierarchie zu verschieben.",
    "catalog_help_page_text_6": "Bitte beachten Sie, dass ausschließlich der jeweils letzte Eintrag auf einer Hierarchie-Ebene auf eine höhere Ebene geschoben werden kann.",
    "catalog_help_page_text_7": "Bevor ein Katalogeintrag einem anderen Beitrag untergeordnet werden kann, muss die Liste der untergeordneten Einträge geöffnet sein. Klicken Sie dazu auf das Ordner-Icon links neben dem entsprechenden Eintrag.",
    "catalog_help_page_text_8": "Neu angelegte Kataloge sind zunächst nur für Sie selbst einsehbar. Ändern Sie die Sichtbarkeit im Menü \"Katalog bearbeiten\", um Ihren Katalog zu veröffentlichen.",
    "catalog_help_page_title_0": "Katalogeinträge anlegen",
    "catalog_help_page_title_1": "Titel und verknüpften Datensatz angeben",
    "catalog_help_page_title_2": "Katalogtext eingeben",
    "catalog_help_page_title_3": "Hierarchien anlegen",
    "catalog_help_page_title_4": "Reihenfolge von Katalogeinträgen ändern",
    "catalog_help_page_title_5": "Hierarchie-Ebene von Katalogeinträgen ändern",
    "catalog_help_page_title_6": "Einträge auf eine höhere Hierarchie-Ebene verschieben",
    "catalog_help_page_title_7": "Einträge auf eine tiefere Hierarchie-Ebene verschieben",
    "catalog_help_page_title_8": "Kataloge veröffentlichen",
    "catalog_help_markdown_link_description": "Nähere Informationen über Markdown finden Sie hier.",
    "catalog_help_markdown_link_url": "https://de.wikipedia.org/wiki/Markdown",
    "catalog_help_previous": "Zurück",
    "catalog_help_next": "Weiter",
    "facets": "Facetten",
    "facet_values": "Filterwerte",
    "catalog_403": "Sie verfügen nicht über die nötige Berechtigung, um auf diesen Katalog zuzugreifen.",
    "facet_thema": "Thema",
    "facet_lebensalter": "Lebensalter",
    "facet_fotonrextern": "Externe Fotonummer",
    "facet_geschlecht": "Geschlecht",
    "ui_show_less": "Weniger anzeigen",
    "navbar_sign_in": "Anmelden",
    "ui.passwordreset.fieldMissing.lastname": "Bitte geben Sie Ihren Nachnamen ein",
    "facet_aufnahmedatum": "Aufnahmedatum",
    "facet_schlagworte": "Schlagworte",
    "msg_confirm_dismiss_changes": "Schließen? Änderungen werden nicht gespeichert.",
    "ui.passwordchange.change": "Passwort ändern",
    "ui_pwdchange": "Passwort ändern?",
    "ui.passwordchange.oldpassword": "Altes Passwort",
    "ui.passwordchange.newpassword": "Neues Passwort",
    "ui.passwordchange.repeat-newpassword": "Neues Passwort wiederholen",
    "ui.passwordchange.success": "Änderung erfolgreich",
    "ui.passwordchange.wrongpassword": "Sie haben Ihr Passwort falsch eingegeben!",
    "ui.passwordchange.wrongCheck": "Die neuen Passwörter stimmen nicht überein!",
    "facet_wesen": "Wesen",
    "facet_bewaffnung": "Bewaffnung",
    "facet_attributallg": "Attribute allgemein",
    "facet_realienart": "Realienart",
    "facet_bennenungallg": "Benennung allgemein",
    "ui.passwordchange.illegal": "Das alte und das neue Passwort dürfen nicht die selben sein!",
    "edit_button": "Bearbeitung",
    "image_archive_button": "Archiv",
    "catalog_no_catalog_info": "Ein Katalog in Arachne ist geordnetes Verzeichnis von archäologischen Objekten.",
    "catalog_no_catalog_help": "Mit einem Klick auf \"Neuen Katalog anlegen\", kann man einen eigenen persönlichen Katalog erstellen. Weitere Hilfe zum Bearbeiten von Einträgen findet man wenn man den Katalog öffnet.",
    "catalog_no_catalog_hint_note": "Kataloge sind standardmäßig privat, sodass sie nicht öffentlich sichtar sind. Außerdem müssen weitere Bearbeiter gesondert freigeschaltet werden.",
    "general_hint": "Hinweis:",
    "ui_on": "Vor",
    "ui_relevance": "Relevanz",
    "ui_tiles": "Kacheln",
    "ui_list": "Liste",
    "catalog_add_user": "Bearbeiter hinzufügen",
    "ui_user_not_found": "Es konnte kein Benutzer mit diesem Namen gefunden werden.",
    "catalog_no_editor": "Dieser Benutzer verfügt über keine Bearbeiterrechte oder ist bereits hinzugefügt worden.",
    "catalog_editors": "Bearbeiter",
    "catalog_atleast_one_editor": "Ein Katalog benötigt mindestens einen Bearbeiter.",
    "facet_datierungargument": "Datierung; Argument",
    "facet_fundkontext": "Fundkontext",
    "catalog_projectId": "Projekt ID",
    "catalog_delete_entries": "Mehrere Einträge löschen",
    "catalog_delete_entries_button": "Einträge löschen",
    "entity_project_info": "Projekte mit diesem Eintrag",
    "entity_projects": "Projekte",
    "type_singular_literatur": "Literatur",
    "type_singular_sammler": "Sammler",
    "type_gruppierung": "Gruppierungen",
    "type_singular_gruppierung": "Gruppierung",
    "type_singular_modell3d": "3D-Modell",
    "type_subtitle_literatur": "TODO",
    "type_subtitle_ort": "TODO",
    "type_subtitle_sammler": "TODO",
    "type_subtitle_person": "TODO",
    "type_subtitle_gruppierung": "TODO",
    "type_subtitle_modell3d": "TODO",
    "search_400": "Ungültige Suchanfrage. Vergewissern Sie sich, dass alle Sonderzeichen korrekt maskiert wurden. Für genauere Anweisungen steht unser FAQ zur Verfügung: https://arachne.dainst.org/info/faq",
    "ui_map_legende": "Anzahl der Treffer",
    "ui_map_legende_min": "niedrig",
    "ui_map_legende_max": "hoch",
    "search_500": "Es ist ein interner Serverfehler bei der Bearbeitung Ihrer Suchanfrage aufgetreten. Dies sollte nicht passieren. Bitte kontaktieren Sie idai.objects@dainst.de so dass wir diesen Fehler beheben können.",
    "catalog_preview": "Übersicht",
    "ui_download_as": "Herunterladen als",
    "ui_download_limit": "Angabe der Anzahl an Ergebnissen",
    "ui_download_limit_other": "Benutzerdefiniert",
    "ui_download": "Herunterladen",
    "ui_download_select": "Bitte den gewünschten Dateityp auswählen",
    "ui_download_big": "Achtung, die Auswahl ist sehr groß und es könnte dauern die Datei zu erstellen.",
    "ui_download_too-big": "Die Auswahl überschreitet das Limit von 10000 Objekten. Bitte benutzen Sie Filter um die Auswahl einzugrenzen.",
    "facet_fabriccommonname": "Allgemeiner Fabrikatname",
    "search_whole_arachne": "In der gesamten Arachne suchen.",
    "search_current_scope": "im Suchbereich",
    "group_facet_place": "Ort",
    "group_facet_material": "Material",
    "group_facet_funktion": "Funktion",
    "group_facet_thematik": "Thematik",
    "facet_nominal": "Nominal",
    "facet_muenzstaette": "Münzstätte",
    "group_facet_zustand": "Zustand",
    "group_facet_motiv": "Representation",
    "group_facet_typ": "Typ",
    "add_entity_to_catalogue": "Datensatz zu Katalog hinzufügen",
    "group_facet_buildingtype": "Gebäudetypus",
    "facet_modal_search": "Suche nach einen Filter...",
    "facet_modal_result": "Gefunden",
    "ui_unsaved-changes": "Sie haben Ihre Änderungen nicht gespeichert. Möchten Sie diese Seite verlassen, ohne Ihre Änderungen zu speichern?",
    "catalog_create_entries": "Mehrere Einträge anlegen",
    "catalog_singular_entry": "Einzelner Eintrag",
    "catalog_multiple_entries": "Mehrere Einträge",
    "catalog_import_by_id": "Mehrere Einträge über Entity-IDs importieren",
    "catalog_import_by_id_instructions": "Geben Sie im Textfeld alle zu importierenden Entity-IDs ein",
    "catalog_add_entitiyids_comma_separated": "Trennen Sie mehrere Entity IDs mit einem Komma und benutzen Sie keine Leerzeichen",
    "catalog_add_entry_help": "Fügen Sie einen Eintrag zu dieser Liste hinzu.",
    "catalog_create_entries_instructions": "Nachdem die Liste vollständig ist, bestätigen Sie mit dem OK Knopf",
    "catalog_remove_entry_help": "Entfernt diesen Eintrag aus der Liste",
    "translocation_view_to_many_results": "Es sind zu viele Ergebnisse gewählt um Objektbewegungen zeigen zu können. Zommen Sie näher heran oder schränken Sie die Ergebnismenge durch Filter ein um die Visualisierung zu ermöglichen.",
    "translocations": "Translokationen",
    "show_translocation_view": "Objektbewegungen Visualieren",
    "bounding_box": "Kartenausschnitt",
    "ui.register.dataProtection": "Bitte bestätigen sie die Hinweise zum Datenschutz, um mit der Registrierung fortzufahren.",
    "ui_data_protection_pre": "Ich habe die",
    "ui_data_protection_post": "gelesen und erkläre mich mit ihnen einverstanden.",
    "ui_data_protection": "Hinweise zum Datenschutz",
    "ui_data_protection_url": "https://www.dainst.org/datenschutz",
    "facet_region": "Region",
    "facet_subregion": "Subregion",
    "facet_locality": "Lokalisierung",
    "facet_city": "Stadt",
    "facet_titel": "Titel",
    "facet_ethnienationalitaet": "Nationalität",
    "facet_inhaltlichekategorie": "inhaltliche Kategorie",
    "catalog_project": "Projektseite anzeigen",
    "ui_forgot_password_alert_pre": "Haben Sie ihr Passwort vergessen? Klicken Sie",
    "ui_forgot_password_alert": "hier",
    "ui_forgot_password_alert_post": "um es zurückzusetzen.",
    "catalog_download": "Katalog Herunterladen",
    "ui_ok": "OK",
    "ui_data_export_status_finished": " Fertig",
    "ui_data_export_status_error": " Fehler",
    "ui_data_export_status_enqueued": " Eingereiht",
    "ui_data_export_status_running": " Läuft",
    "ui_data_export_status": " Daten-Export - Status",
    "ui_owner": " Besitzer",
    "ui_created_at": " Erstellt am",
    "ui_started_at": " Startet am",
    "ui_media_type": " Dateityp",
    "ui_url": " URL",
    "ui_status": " Status",
    "ui_data_export_other_user": " Dieser Export gehört einem anderen User. Sie bekommen ihn angezeigt, weil Sie admin sind.",
    "data_export_to_huge_and_will_be_sent_by_mail": " Dieser Export ist sehr groß und dauert eine Weile. Sie erhalten eine Benachichtigung, per Email wenn er fertig ist.",
    "data_export_to_huge_and_not_logged_in": " Dieser Export ist sehr groß. Bitte loggen Sie sich ein, um ihn zu starten.",
    "data_export_io_error": " Datei-Zugriffsfehler",
    "data_export_no_admin": " Nur Admins dürfen diese Aktion ausführen",
    "data_export_not_found": " Datei nicht gefunden",
    "data_export_stack_full": " Der Export-Stapel ist leider voll. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.",
    "ui_data_export_none": "Sie haben keinen Export in Ihrer Liste.",
    "ui_restore_call": "Quelle Wiederherstellen",
    "ui_catalog": "Katalog",
    "ui_searchResult": "Suchergebnis",
    "ui_to": "nach",
    "type_mainabstract": "Keramik",
    "ui.update.error": "Es ist ein unerwarteter Fehler bei der Aktualisierung ihrer Daten aufgetreten.",
    "type_modell3d": "3D-Modelle",
    "catalog_duplicate_entry_child": "Eintrag verdoppeln",
    "all_images": "Alle Bilder",
    "show_images": "anzeigen",
    "ui_running_for": "Bisherige Laufzeit",
    "all": "Alle",
    "ui_username": "Benutzername",
    "ui_please_select": "Bitte auswählen",
    "ui_password": "Passwort",
    "ui_entries": "Einträge",
    "ui_total": "Insgesamt",
    "footer_privacy": "Datenschutz",
    "backend_missing": "Die Verbindung zum Arachne-Server konnte nicht hergestellt werden.",
    "default": "Ein unbekannter Fehler ist aufgetreten.",
    "ui_filter_plural": "Filter",
    "pick_an_item": "Wählen Sie einen Eintrag",
    "form_save": "Speichern",
    "form_reset": "Zurücksetzen",
    "picker_perform_search": "Starten Sie eine Suchanfrage, um einen Eintrag auszuwählen",
    "picker_no_result": "Keine Einträge gefunden",
    "results": "Ergebnisse",
    "everything_fine": "Alles in bester Ordnung",
    "components.message.contact": "Bitte kontaktieren Sie CONTACT, sollte der Fehler weiterhin auftreten.",
    "ui_login": "Anmeldung",
    "ui_login_successfull": "Anmeldung erfolgreich. Nutzer",
    "ui_login_failed": "Anmeldung fehlgeschlagen, bitte erneut versuchen!",
    "ui_page": "Seite",
    "ui_previous": "Zurück",
    "ui_next": "Weiter",
    "navbar_placeholder_search": "Neue Suche",
    "contact_success": "Nachricht versendet. Sie sollten in Kürze eine eMail von uns erhalten.",
    "navbar_sign_up": "Registrieren",
    "footer_licensed_under": "Lizensiert unter",
    "footer_bugs_to": "Fehlermeldungen bitte an",
    "contact_first_surname": "Vor- & Nachname",
    "ui_subject": "Betreff",
    "ui_message": "Nachricht",
    "ui_nomachine": "Ich bin keine Maschine",
    "ui_send": "Senden",
    "ui_placeholder_search": "Suche",
    "ui_showall": "Alle anzeigen",
    "test": "von \"shared\"",
    "navbar_sign_out": "Abmelden",
    "navbar_old_searches": "Ältere Suchen",
    "ui_back": "Zurück",
    "ui_add_value": "Wert hinzufügen",
    "ui_add_tag": "Tag hinzufügen",
    "ui_add_name": "Namen hinzufügen",
    "ui_add_language": "Sprache hinzufügen",
    "navbar_this_scope": "Dieser Suchbereich",
    "navbar_leave_scope": "Suchbereich verlassen",
    "ui.con10t-network.too_many_entities": "Bitte schränken Sie ihre Suche auf 300 Ergebnisse oder weniger ein.",
    "group_facet_person": "Personen",
    "facet_personroleauthor": "Autoren",
    "facet_personroletopic": "Erwähnte",
    "facet_personroleaddressee": "Adressaten",
    "facet_personrolenone": "Sonstige",
    "catalog_progress": "Katalog wird erstellt",
    "catalog_progress_complete": "Erfolgreich abgeschlossen",
    "catalog_progress_catalog_link": "Zum Katalog"
};
